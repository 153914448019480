import { ref, computed } from '@vue/composition-api'
import debounce from 'lodash/debounce'

import store from '@/store'
import api from '@/api/'

import {
  dataURLtoFile, downloadURL, uploadFilesDocuments, useRouter,
} from '@core/utils/utils'

import useToast from '@useToast'

export default function useDocumentHandle() {
  const { toastSuccess, toastError } = useToast()
  const { route } = useRouter()

  const loading = ref(null)

  // ANCHOR: Permission
  const isRoleF1 = computed(() => store.getters['userStore/getRoleMama'])
  const isRoleF2 = computed(() => store.getters['userStore/getRoleF2'])
  const meData = computed(() => store.getters['userStore/getMeData'])

  const canModify = computed(() => {
    const myType = meData?.value?.type
    if (!myType) return false
    if (isRoleF1.value && ['SE', 'ADM', 'OPE'].includes(myType)) return true
    if (isRoleF2.value && ['ADM', 'OPE'].includes(myType)) return false // FIXME
    return false
  })

  // DOCUMENT
  const documentTableColumns = ref([
    { label: 'Name', key: 'name' },
    { label: 'File', key: 'file' },
    { label: 'status', key: 'status' },
    // { label: 'isShow', key: 'isShow' }, // Công văn BGT
    // { label: 'isActive', key: 'isActive' }, // Đăng bài
    { label: 'Created At', key: 'createdAt' },
    { label: 'Updated At', key: 'updatedAt' },
    { label: 'Action', key: 'action' },
  ])
  const refDocumentListTable = ref(null)
  const totalDocuments = ref(0)

  const folderId = computed(() => route.value.params.id)

  const refetchDocumentData = () => {
    refDocumentListTable.value.refresh()
  }

  // SECTION FOLDERS
  const folderTableColumns = ref([
    // { label: '', key: 'checkbox' },
    { label: 'Folder', key: 'name' },
    { label: 'Created At', key: 'createdAt' },
    { label: 'Updated At', key: 'updatedAt' },
    { label: 'Action', key: 'action' },
  ])

  const refFolderListTable = ref(null)
  const folderNameFilter = ref(null)
  const documentNameFilter = ref(null)
  const totalFolders = ref()
  const refetchFolderData = () => {
    refFolderListTable.value.refresh()
  }

  // ANCHOR Fetch Folders
  const fetchAllFolders = (ctx, callback) => {
    loading.value = true
    const params = {
      name: folderNameFilter.value || '',
    }
    store.dispatch('app-documents/fetchAllFolders', params)
      .then(response => {
        totalFolders.value = response.data.total
        const documents = response.data.items
        callback(documents)
      })
      .catch(() => {
        toastError({
          title: 'messagesList.error',
          content: 'document.message.fetch.error',
        })
      })
      .then(() => {
        loading.value = false
      })
  }
  // !SECTION

  const fetchAllDocuments = () => {
    loading.value = true
    const params = {
      id: folderId.value,
      name: documentNameFilter.value || '',
    }
    return store
      .dispatch('app-documents/fetchAllDocuments', params)
      .then(response => {
        totalDocuments.value = response.data.total
        const documents = response.data.items
        return documents
      })
      .catch(() => {
        toastError({
          title: 'messagesList.error',
          content: 'document.message.fetch.error',
        })
      })
      .finally(() => {
        loading.value = false
      })
  }

  const getDocumentById = id => new Promise((resolve, reject) => {
    loading.value = true
    store
      .dispatch('app-documents/getDocumentById', { id })
      .then(res => {
        resolve(res)
      })
      .catch(error => {
        reject(error)
        toastError({
          title: 'messagesList.error',
          content: 'document.message.fetch.error',
        })
      })
      .finally(() => {
        loading.value = false
      })
  })

  const addDocument = async (document, callback = () => {}) => {
    loading.value = true
    await store
      .dispatch('app-documents/addDocument', document)
      .then(() => {
        callback()
        toastSuccess({
          title: 'messagesList.success',
          content: `document.message.add.${document.type === 'FOLDER' ? 'folderSuccess' : 'documentSuccess'}`,
        })
        store.dispatch('globalConfig/getAllPins')
      })
      .catch(() => {
        toastError({
          title: 'messagesList.error',
          content: `document.message.add.${document.type === 'FOLDER' ? 'folderError' : 'documentError'}`,
        })
      })
      .finally(() => {
        loading.value = false
      })
  }

  const updateDocument = async (documentData, dataToUpdate, callback = () => {}) => {
    loading.value = true
    await store
      .dispatch('app-documents/updateDocument', {
        id: documentData.id,
        dataToUpdate,
      })
      .then(() => {
        callback()
        toastSuccess({
          title: 'messagesList.success',
          content: `document.message.update.${document.type === 'FOLDER' ? 'folderSuccess' : 'documentSuccess'}`,
        })
        store.dispatch('globalConfig/getAllPins')
      })
      .catch(() => {
        toastError({
          title: 'messagesList.error',
          content: `document.message.update.${document.type === 'FOLDER' ? 'folderError' : 'documentError'}`,
        })
      })
      .finally(() => {
        loading.value = false
      })
  }

  const deleteDocument = (document, callback = () => {}) => {
    loading.value = true
    store
      .dispatch('app-documents/deleteDocument', document.id)
      .then(() => {
        toastSuccess({
          title: 'messagesList.success',
          content: `document.message.delete.${document.type === 'FOLDER' ? 'folderSuccess' : 'documentSuccess'}`,
        })
        callback()
        store.dispatch('globalConfig/getAllPins')
      })
      .catch(() => {
        toastError({
          title: 'messagesList.error',
          content: `document.message.delete.${document.type === 'FOLDER' ? 'folderError' : 'documentError'}`,
        })
      })
      .finally(() => {
        if (document.type === 'FOLDER') refetchFolderData()
        if (document.type === 'DOCUMENT') refetchDocumentData()
        loading.value = false
      })
  }

  const togglePinDocument = (document, callback = () => {}) => {
    loading.value = true
    store
      .dispatch('app-documents/pinDocument', { id: document.id, payload: { isPin: !document.isPin, priorityPin: document?.priorityPin || 'LOW' } })
      .then(() => {
        toastSuccess({
          title: 'messagesList.success',
          content: `document.message.${document.isPin ? 'unpinSuccess' : 'pinSuccess'}`,
        })
        callback()
        store.dispatch('globalConfig/getAllPins')
      })
      .catch(() => {
        toastError({
          title: 'messagesList.error',
          content: `document.message.${document.isPin ? 'unpinError' : 'pinError'}`,
        })
      })
      .finally(() => {
        if (document.type === 'FOLDER') refetchFolderData()
        if (document.type === 'DOCUMENT') refetchDocumentData()
        loading.value = false
      })
  }

  // NOTE: bulkDelete unused
  const bulkDeleteDocuments = ids => {
    store
      .dispatch('app-documents/bulkDeleteDocuments', { ids })
      .then(() => {
        refetchDocumentData()
        toastSuccess({
          title: 'messagesList.success',
          content: `document.message.delete.${document.type === 'FOLDER' ? 'folderSuccess' : 'documentSuccess'}`,
        })
      })
      .catch(() => {
        toastError({
          title: 'messagesList.error',
          content: `document.message.delete.${document.type === 'FOLDER' ? 'folderError' : 'documentError'}`,
        })
      })
  }

  // fetchFolder
  const folderList = ref([])
  const folderListDefault = ref([])

  const fetchFolderByFilter = async (search = '') => {
    folderList.value = []
    loading.value = true
    try {
      const folders = await api.document.fetchAllFolders({})
      if (folders && !search) {
        folderListDefault.value = folders.data.items
      }
      folderList.value = folders.data.items
    } catch (error) {
      console.error(error)
    } finally {
      loading.value = false
    }
  }

  const openFolder = () => {
    if (folderListDefault.value?.length === 0) {
      fetchFolderByFilter()
    } else {
      folderList.value = folderListDefault.value
    }
  }

  const searchFolder = debounce(search => {
    if (search) fetchFolderByFilter(search)
  }, 500)

  const shortenFileName = (fileName, lengthToSlice = 20) => {
    const splitName = fileName.split('.')
    const ext = splitName[splitName.length - 1]
    const name = splitName.slice(0, splitName.length - 1).join()
    const shortName = name.slice(0, lengthToSlice - 1)
    return `${shortName}${name.length > lengthToSlice ? '(...)' : ''}.${ext}`
  }

  // eslint-disable-next-line consistent-return
  function confirmDownloadFile(item) {
    const allowedExtensions = ['xlsx', 'docx', 'doc', 'exe', 'txt', 'xls']
    const fileExtension = item.file.split('.').pop().toLowerCase()

    if (!allowedExtensions.includes(fileExtension)) {
      return downloadURL(item.url, item.file)
    }

    const titleMsg = () => this.$createElement('div', { domProps: { innerHTML: `Bạn có muốn tải xuống file <em class='font-weight-bolder'>${this.shortenFileName(item?.file)}</em> ?` } })
    this.$bvModal
      .msgBoxConfirm(titleMsg(), {
        title: 'TẢI FILE',
        size: 'sm',
        okVariant: 'info',
        okTitle: 'Tải xuống',
        cancelTitle: 'Huỷ',
        cancelVariant: 'outline-danger',
        hideHeaderClose: true,
        centered: true,
      })
      .then(value => {
        if (value) {
          downloadURL(item.url, item.file)
        }
      })
  }

  return {
    fetchAllFolders,
    getDocumentById,
    addDocument,
    updateDocument,
    deleteDocument,
    bulkDeleteDocuments,
    togglePinDocument,
    loading,
    folderId,

    // Document
    refDocumentListTable,
    documentTableColumns,
    totalDocuments,
    refetchDocumentData,
    fetchAllDocuments,

    // Folder
    refFolderListTable,
    folderTableColumns,
    totalFolders,
    folderNameFilter,
    documentNameFilter,
    refetchFolderData,
    folderList,
    folderListDefault,
    searchFolder,
    openFolder,
    // Permission
    canModify,

    shortenFileName,
    confirmDownloadFile,

    // FOR UPLOAD IMG
    handleUploadFiles: uploadFilesDocuments,
    dataURLtoFile,
  }
}
